<template>
  <b-card>

    <div  v-if="datas.length > 0">
      <b-form-checkbox v-model="isAll" name="check-button" switch >
        Хаагдсан зээл харуулахгүй
      </b-form-checkbox>

      <!-- <download-excel
      class="btn btn-primary pull-right ml-3 mb-3"
      :fetch="fetchData"
      :fields="download.header"
      :before-generate="startDownload"
      :before-finish="finishDownload"
      :name="'Мэдээлэлийн сан - ' + register + '.xls'"
    >
      <b-spinner small type="grow" v-show="download.loading"></b-spinner>
      <span v-show="download.loading" class="ml-1">Уншиж байна</span>
      <i class="fa fa-download" v-show="!download.loading"></i>
      <span v-show="!download.loading" class="ml-1">Excel татах</span>
    </download-excel> -->

    <b-form @submit.stop.prevent="pdf_download">

      <b-button class="btn btn-primary pull-right ml-3 mb-3" type="submit" variant="primary"
        >
        <i class="fa fa-download" v-show="!download.loading"></i>
        PDF татах
        </b-button
      >
    </b-form>
    </div>
    <table
      class="table table-sm table-bordered mb-0"
      stacked="md"
      hover
      striped
      bordered
      small
    >
      <tr v-if="cus_info">
          <th colspan="12">
            <h3 class="m-2">{{ cus_info['cust_lname'] }} {{ cus_info['cust_name'] }}</h3>
          </th>
        </tr>
      <tr style="padding-top: 30px" class="bg-light">
        <th class="text-center">№</th>
        <th>Зээлийн төрөл</th>
        <th>Зээл өгсөн байгууллага</th>
        <th>Зээл олгосон огноо</th>
        <th>Зээл төлөгдөж дуусах огноо</th>
        <th>Зээл төлөгдөж дуусах шинэчилсэн огноо</th>
        <th>Хаасан огноо</th>
        <th class="text-center">Хүү</th>
        <th>Олгосон дүн</th>
        <th>Үлдэгдэл</th>
        <th>Сард төлөх зээл</th>
        <th class="text-center">Валют</th>
        <th class="text-center">Зээлийн ангилал</th>
        <th>Зээлийн зориулалт</th>
        <th>Барьцаа</th>
      </tr>
      <tbody v-if="datas.length > 0">
        <tr v-for="(loan, index) in datas" :key="index" :class="getBgColor(loan)">
          <template v-if="loan.balance > 0 || isAll">
            <td class="text-center">{{ index + 1 }}</td>
            <td>{{ loan.dutytypename }}</td>
            <td>{{ loan.clientname }}</td>
            <td class="text-center">{{ loan.adv_date }}</td>
            <td class="text-center">{{ loan.maturity_date }}</td>
            <td class="text-center">{{ loan.maturity_date2 }}</td>
            <td class="text-center">{{ loan.closed_date }}</td>
            <td class="text-center">{{ loan.int_rate }}</td>
            <td class="text-right">{{ loan.adv_amount | currecryZero }}</td>
            <td class="text-right">{{ loan.balance | currecryZero }}</td>
            <td class="text-right">{{ loan.monthly_payment | currecryZero }}</td>
            <td class="text-center">{{ loan.curr_code }}</td>
            <td class="text-center">{{ loan.cls_name }}</td>
            <td>{{ loan.purp_name }}</td>
            <td>{{ loan.colls[0].sub_coll_type }}</td>
          </template>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="15">
            <b-alert show class="mt-3 mr-3 ml-3" variant="info"
              >Зээлийн түүх байхгүй байна</b-alert
            >
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  name: "Cib.ZMS",
  props: {
    register: { type: String, default: "" },
    datas: { type: [Array, Object], default: () => [] },
    cus_info: { type: [Array, Object], default: () => [] },
    file_url: ""
  },
  data: () => {
    return {
      bg_colors: {
        '01': 'table-white',
        '02': 'table-primary',
        '03': 'table-info',
        '04': 'table-warning',
        '05': 'table-danger'
      },
      data: [],
      download: {
        loading: false,
        header: {},
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Зээлийн төрөл", key: "dutytypename" },
        { label: "Зээл өгсөн байгууллага", key: "clientname" },
        { label: "Зээл олгосон огноо", key: "adv_date" },
        { label: "Зээл төлөгдөж дуусах огноо", key: "maturity_date" },
        {
          label: "Зээл төлөгдөж дуусах шинэчилсэн огноо",
          key: "maturity_date2",
        },
        { label: "Хүү", key: "int_rate" },
        { label: "Олгосон дүн", key: "adv_amount" },
        { label: "Үлдэгдэл", key: "balance" },
        { label: "Валют", key: "curr_code" },
        { label: "Зээлийн ангилал", key: "cls_name" },
        { label: "Зээлийн зориулалт", key: "purp_name" },
      ],
      isAll: true
    };
  },
  components: { axios },
  created: function() {
    // this.$data.query.id = this.customerId;
    this.$data.download.header = {};
    for (let i in this.$data.columns) {
      let _field = this.$data.columns[i];
      this.$data.download.header[_field.label] = _field.key;
    }
    console.log(this.$data.download.header);
  },

  methods: {
    pdf_download: function() {
    if (this.file_url !== "") {
      this.isLoading = true;

      const formData = new FormData();
      formData.append('email', this.$store.getters.email);  // Add email
      formData.append('file_url', this.file_url);          // Add file_url

      axios
        .post(
          this.$config.NUMUR_MS_ADMIN_URL + "pdf_download",  // Your Flask backend URL
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
            responseType: 'arraybuffer', 
          }
        )
        .then((response) => {
          this.isLoading = false;

          // Handle PDF download
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `ZMS_${this.register}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error downloading PDF:", error);
          this.showToast("Алдаа", "PDF татаж авахад алдаа гарлаа", "danger");
        });
    } else {
      this.showToast("Анхаар", "Session хоосон байна", "warning");
    }
  },

    async fetchData() {
      if (this.download.loading) {
        return this.datas;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
    getBgColor: function(loan) {
      let color = 'table-white'

      if(loan.balance > 0) {
        if(loan.cls_code) {
          color = this.$data.bg_colors[loan.cls_code]
        }
      } else {
        color = 'table-active'
      }
      return color;
    }
  },
};
</script>
